import classNames from 'classnames'
import React from 'react'
import Button from 'components/Button'
import Buttons from 'components/Buttons'
import Container from 'components/Container'
import Link from 'components/Link'
import Text from 'components/Text'
import paths from 'constants/paths'
import type { Component } from 'constants/types'
import urls from 'constants/urls'
import styles from './NavHeader.module.scss'
import { ReactComponent as FacebookSvg } from './images/socials/facebook.svg'
import { ReactComponent as InstagramSvg } from './images/socials/instagram.svg'

//import { ReactComponent as TwitterSvg } from './images/socials/twitter.svg'

const CATEGORIES = [
  'All',
  //'Community',
  'Exercise',
  'Lifestyle',
]

const SOCIALS = [
  {
    component: FacebookSvg,
    platform: 'Facebook',
    to: urls.FACEBOOK,
  },
  //{
  //  component: TwitterSvg,
  //  platform: 'Twitter',
  //  to: urls.TWITTER,
  //},
  {
    component: InstagramSvg,
    platform: 'Instagram',
    to: urls.INSTAGRAM,
  },
]

type Props = {
  active?: string
} & Component

export default function NavHeader({ active, className }: Props) {
  return (
    <header className={classNames('NavHeader', styles.this, className)}>
      <Container size="xxlarge" className={styles.inner}>
        <div className={styles.filters}>
          <Text casing="upper" weight="semibold" className={styles['filters--label']}>
            Filter by
          </Text>
          <nav>
            <Buttons>
              {CATEGORIES.map((item) => (
                <Button
                  to={
                    item.toLowerCase() === 'all'
                      ? paths.BLOG
                      : `${paths.BLOG}${item.toLowerCase()}/`
                  }
                  active={active === item.toLowerCase()}
                  color="white"
                  key={item}
                  pill>
                  {item}
                </Button>
              ))}
            </Buttons>
          </nav>
        </div>
        <Buttons>
          {SOCIALS.map((item) => (
            <Link
              to={item.to}
              color="grey"
              key={item.platform}
              aria-label={`Bold on ${item.platform}`}>
              <item.component aria-hidden />
            </Link>
          ))}
        </Buttons>
      </Container>
    </header>
  )
}
